export const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

export const handleSubmit = (event, formEl, setState, state, callback) => {
  event.preventDefault();
  const form = formEl.current;
  fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encode({
      'form-name': form.getAttribute('name'),
      ...state,
    }),
  })
    .then(() => callback())
    .catch(error => alert(error));

  setState({
    name: '',
    email: '',
    message: '',
  });
};
