import React, { useRef, useState } from 'react';
import { navigate } from 'gatsby';

import Button from 'components/button';
import Hero from 'components/hero';
import DefaultLayout from 'layouts/default';
import { handleSubmit } from 'helpers';

const title = 'Contact';

const ContactPage = () => {
  const formEl = useRef(null);
  const [state, setState] = useState({ name: '', email: '', message: '' });
  const [loading, setLoading] = useState(false);

  return (
    <DefaultLayout
      lang="en"
      metaDescription="Ever thought about quitting your city life and moving to a quiet village? Got questions about renovating an old house? Drop us a line and we'll get back to you."
      title={title}
      url="/contact"
    >
      <Hero withBorder title={title} />
      <main>
        <form
          className="form"
          name="contact"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={e => {
            setLoading(true);
            handleSubmit(e, formEl, setState, state, () => {
              window.setTimeout(() => {
                navigate('/en/success');
              }, 500);
            });
          }}
          ref={formEl}
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <label className="form-label" htmlFor="name">
            Name
            <input
              className="form-input"
              type="text"
              name="name"
              id="name"
              required
              onChange={e => {
                setState({ ...state, name: e.target.value });
              }}
            />
          </label>
          <label className="form-label form-label--margin" htmlFor="email">
            Email
            <input
              className="form-input"
              type="email"
              name="email"
              id="email"
              required
              onChange={e => {
                setState({ ...state, email: e.target.value });
              }}
            />
          </label>
          <label className="form-label form-label--margin" htmlFor="message">
            Message
            <textarea
              className="form-input form-textarea"
              name="message"
              id="message"
              required
              onChange={e => {
                setState({ ...state, message: e.target.value });
              }}
            />
          </label>
          <Button className="form-button" isLoading={loading} type="submit">
            Send
          </Button>
        </form>
      </main>
    </DefaultLayout>
  );
};

export default ContactPage;
