import React from 'react';
import cx from 'classnames';

const Button = ({
  as: Tag = 'button',
  children,
  className,
  isLoading = false,
  ...other
}) => {
  return (
    <Tag className={cx('button', className)} disabled={isLoading} {...other}>
      {isLoading ? (
        <div class="button-loader" aria-label="Loading">
          <div class="button-loader-bounce button-loader-bounce--1"></div>
          <div class="button-loader-bounce button-loader-bounce--2"></div>
          <div class="button-loader-bounce button-loader-bounce--3"></div>
        </div>
      ) : (
        children
      )}
    </Tag>
  );
};

export default Button;
